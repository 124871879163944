import consumer from "./consumer"

consumer.subscriptions.create("LiveNotificationChannel", {
  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (['/', '/live'].includes(document.location.pathname) && data.is_live) {
      window.location.reload();
    }
  }
});
